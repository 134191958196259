import React from 'react';
import { useFormikContext, FieldArray } from 'formik';
import {
  TextField,
  IconButton,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { Delete } from '@mui/icons-material';

const ChooseOptions = ({ getMessage, buttonStyle }) => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext();

  const styles = {
    formGroup: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '1rem',
    },
    inputGroup: {
      display: 'flex',
      alignItems: 'center',
    },
  };

  return (
    <>
      <Typography variant="h4">{getMessage('label_answer_options')}</Typography>

      <FieldArray name="options">
        {({ remove, push }) => (
          <>
            {values.options.map((option, index) => (
              <div key={index} style={styles.formGroup}>
                <div style={styles.inputGroup}>
                  <TextField
                    id={`options.${index}`}
                    name={`options.${index}`}
                    placeholder={`${getMessage('label_option')} ${index + 1}`}
                    value={option}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.options &&
                      touched.options[index] &&
                      errors.options &&
                      errors.options[index]
                        ? true
                        : false
                    }
                    helperText={
                      touched.options &&
                      touched.options[index] &&
                      errors.options &&
                      errors.options[index]
                        ? errors.options[index]
                        : ''
                    }
                    sx={{ width: '80%' }}
                  />
                  <IconButton onClick={() => remove(index)}>
                    <Delete />
                  </IconButton>
                </div>
              </div>
            ))}

            <Button
              onClick={() => push('')}
              variant="contained"
              sx={{ ...buttonStyle, mb: 5 }}
            >
              <Typography variant="h5">{getMessage('label_add_option')}</Typography>
            </Button>

            <FormControl fullWidth>
              <InputLabel id="selectedCorrectAnswer">
                {getMessage('label_choose_correct_answer')}
              </InputLabel>
              <Select
                labelId="selectedCorrectAnswer"
                id="selectedCorrectAnswer"
                name="correctAnswer"
                value={values.correctAnswer}
                label={getMessage('label_choose_correct_answer')}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.correctAnswer && Boolean(errors.correctAnswer)}
                sx={{ mb: '15px' }}
              >
                {values.options.length > 0 &&
                  values.options.map(
                    (answer, index) =>
                      answer !== '' && (
                        <MenuItem key={index} value={answer}>
                          {answer}
                        </MenuItem>
                      )
                  )}
              </Select>
            </FormControl>
          </>
        )}
      </FieldArray>
    </>
  );
};

export default ChooseOptions;