import { useState, useEffect, useRef } from 'react';
import { ResponsiveBar } from "@nivo/bar";
import { QuestionType } from "../utils/enums";
import { redYellowGreenPalette } from "../components/styledComponents";
import { useMessageService } from '../services/MessageService';
import './BarChartReports.css'; // Import custom CSS for the tooltip
import { useAuthUser } from '../contexts/AuthUserContext';

const BarChartReports = ({ data }) => {
  const chartRef = useRef(null);
  const [tooltipData, setTooltipData] = useState(null);
  const [mouseRelativePosition, setMouseRelativePosition] = useState({ x: 0, y: 0 });

  // color definition
  const pastelBlue = 'rgb(173, 216, 230)';
  const brightPastelBlue = 'rgb(193, 236, 250)';

  // for i18n
  const { getMessage } = useMessageService();
  const { currentUser } = useAuthUser();

  // Calculate the maximum number of choices across all data items
  const maxChoices = data.reduce((max, item) => Math.max(max, item.choices.length), 0);
  // Generate keys based on the maximum number of choices
  const keys = Array.from({ length: maxChoices }, (_, index) => `choice_${index + 1}`);
  // Transform each item to have dynamic keys for the counts
  const transformedData = data.map(item => {
    const newItem = { ...item, shortName: item.uniqueQuestionKey };

    // Each count becomes a separate property
    item.counts.forEach((count, index) => {
      newItem[`choice_${index + 1}`] = count;  // Dynamically set each count as a property
    });

    return newItem;
  });


  useEffect(() => {
    const handleMouseMove = (event) => {
      const chartBounds = chartRef.current.getBoundingClientRect();

      // Calculate the position relative to the chart
      var xPosMouseRelative = (event.clientX - chartBounds.x)/chartBounds.width;
      var yPosMouseRelative = (event.clientY - chartBounds.y)/chartBounds.height;

      xPosMouseRelative = 0.6*xPosMouseRelative + 0.2;
      yPosMouseRelative = 0.6*yPosMouseRelative + 0.2;

      setMouseRelativePosition({
        x: xPosMouseRelative*chartBounds.width,
        y: yPosMouseRelative*chartBounds.height
      });
    };

    if (tooltipData) {
      window.addEventListener('mousemove', handleMouseMove);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [tooltipData]);

  /**
   * Determines the color of a bar in the chart based on the response data.
   *
   * @param {Object} bar - The bar object that needs to be colored, containing information about the bar's id and data.
   * @returns {String} - A string representing the color to be applied to the bar.
  */
  const colorBy = (bar) => {
    // Extract the numeric part from `bar.id` which is expected in the format "choice_x"
    const choiceNumber = parseInt(bar.id.replace('choice_', ''), 10) - 1;

    const questionData = data.find(item => item.uniqueQuestionKey === bar.data.shortName);

    if (!questionData) {
      console.warn('No question data found for:', bar.data.shortName);
      return 'grey';  // Default color when no data matches
    }

    const isMCQ = questionData.correctAnswer !== undefined;
    if (isMCQ) {
      const correctAnswerIndex = questionData.choices.indexOf(questionData.correctAnswer);
      return choiceNumber === correctAnswerIndex ? redYellowGreenPalette[9] : redYellowGreenPalette[0];
    }

    const isUnordered = questionData.type === QuestionType.RADIO_UNORDERED || questionData.type === QuestionType.CHECKBOX;
    if (isUnordered) {
      return choiceNumber % 2 === 0 ? pastelBlue : brightPastelBlue;
    }

    // Handling numeric choice logic
    const numChoices = questionData.choices.length;
    if (numChoices > 1) {
      const paletteIndex = Math.floor(choiceNumber / (numChoices - 1) * (redYellowGreenPalette.length - 1));
      return redYellowGreenPalette[paletteIndex];
    } else if (numChoices === 1) {
      // If only one choice, choose a middle color
      return redYellowGreenPalette[Math.floor(redYellowGreenPalette.length / 2)];
    } else {
      // No choices to map, return default grey
      return 'grey';
    }
  };

  return (
    <div ref={chartRef} style={{ position: 'relative', height: '90%' }}>
      <ResponsiveBar
        data={transformedData}
        keys={keys}
        indexBy="shortName"
        margin={{ top: 15, right: 15, bottom: 15, left: 150 }}
        layout="horizontal"
        groupMode="stacked"
        padding={0.1}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={colorBy}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisLeft={{ tickSize: 5, tickPadding: 5, tickRotation: 0, legend: false, legendPosition: "middle", legendOffset: -30 }}
        enableLabel={true}
        labelSkipWidth={12}
        labelSkipHeight={12}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        role="application"
        tooltip={({ id, value, indexValue }) => {
          const choiceIndex = parseInt(id.replace('choice_', ''), 10) - 1;
          const questionData = data.find(item => item.uniqueQuestionKey === indexValue);

          if (!questionData) {
            return <div>{getMessage("label_no_data_available")}</div>;
          }

          const choice = questionData.choices && questionData.choices.length > choiceIndex ? questionData.choices[choiceIndex] : 'Invalid choice';
          const names = questionData.names && questionData.names.length > choiceIndex ? questionData.names[choiceIndex].join(', ') : 'No answers';

          setTooltipData({
            question: questionData.question,
            choice,
            value,
            names
          });
        }}
        onMouseLeave={() => setTooltipData(null)}
        barAriaLabel={e => `${e.indexValue}: ${e.id} - ${e.value}`}
      />
      {tooltipData && (
        <div className="custom-tooltip" style={{ top: mouseRelativePosition.y, left: mouseRelativePosition.x }}>
          <strong>{tooltipData.question}</strong><br />
          <span>{tooltipData.choice}: {tooltipData.value}</span><br />
          <span>{getMessage("label_respondents")}: {tooltipData.names}</span>

        </div>
      )}
    </div>
  );
};

export default BarChartReports;