import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Box, TextField, Button, Select, MenuItem, InputLabel, Typography, FormControl } from "@mui/material";
import axios from 'axios';
import { BACKEND_URL } from "../config";
import { useAuthUser } from '../contexts/AuthUserContext';
import { useMessageService } from '../services/MessageService';
import { buttonStyle } from "./styledComponents";
import { AssessmentType, LogType } from '../utils/enums';

const AddLogSchema = Yup.object().shape({
  description: Yup.string()
    .min(5, "Description must be at least 5 characters long")
    .max(200, "Description must be at maximum 200 characters long")
    .required("Description is required"),
  day: Yup.string()
    .required("Day is required"),
  assessment: Yup.string()
    .required("Assessment is required"),
  logType: Yup.string()  
    .required("Log type is required"),
  module: Yup.string()
});

const AddLog = ({logs, setLogs, currentMonitoringId, uniqueDays}) => {

  const { currentUser } = useAuthUser();
  const { getMessage } = useMessageService();
  const initialLogValue = { description: "", day: "", assessment:"", logType: "" };

  /* adding a new log*/
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {

    const newLog = {
      description: values.description,
      day: values.day,
      assessment: values.assessment,
      logType: values.logType,
    };

    // Add the new log to the array first
    const updatedLogs = [...logs, newLog];

    // Then update the state
    setLogs(updatedLogs);

    // reset the form
    resetForm({
      values: {
        monitoring: values.monitoring,  // Keep monitoring value
        description: "",
        day: "",
        assessment:"",
        logType: ""
      }
    });


    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${BACKEND_URL}/logs/${currentMonitoringId}/byUser/${currentUser._id}`,
        { log: newLog },
        { headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if(response.status === 200) {
        console.log('Log created successfully');
      } else {
        console.log('An error occurred while creating the log:', response);
      }
    } catch (error) {
      console.log('An error occurred while creating the log:', error);
    }
    
    setSubmitting(false);
};


  return (

    <Box display="flex" flexDirection="column" flex="1" p={2} m={2}>

        <Typography mb="5vh" variant="h3" fontWeight="bold">
          {getMessage("label_add_log_entry")}
        </Typography> 

        <Box sx={{overflowY: 'auto', mt: '15px', height: "70vh"}}>

          <Formik
            initialValues={initialLogValue}
            validationSchema={AddLogSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange, handleBlur }) => (

              <Form>
                <TextField
                  id="description"
                  label={getMessage("label_enter_description")}
                  value={values.description}
                  fullWidth
                  multiline
                  rows={6}
                  sx={{mb:"5%", mt:"5%"}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.description && errors.description ? true : false}
                  helperText={touched.description && errors.description ? errors.description : ""}
                />
                
                <FormControl fullWidth>
                  <InputLabel id="day">
                    {getMessage("label_choose_session")}
                  </InputLabel>

                  <Select
                    labelId="day"
                    id="day"
                    name="day"
                    value={values.day}
                    label={getMessage("label_choose_session")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.day && errors.day ? true : false}
                    sx={{mb:"5%"}}
                  >
                    {uniqueDays.map(day => (
                      <MenuItem key={day} value={`${day}`}>{day}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="assessment">{getMessage("label_concerns")}</InputLabel>
                  <Select
                    labelId="assessment"
                    id="assessment"
                    name="assessment"
                    value={values.assessment}
                    label="Concerns..."
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.assessment && errors.assessment ? true : false}
                    sx={{mb:"5%"}}
                  >
                    <MenuItem value="General">{getMessage("label_type_general")}</MenuItem>
                    <MenuItem value={AssessmentType.TRAINEE_CHARACTERISTICS}>{getMessage('label_assessment_type_trainee_characteristics')}</MenuItem>
                    <MenuItem value={AssessmentType.TRAINING_CHARACTERISTICS}>{getMessage('label_assessment_type_training_characteristics')}</MenuItem>
                    <MenuItem value={AssessmentType.IMMEDIATE_REACTIONS}>{getMessage('label_assessment_type_immediate_reactions')}</MenuItem>
                    <MenuItem value={AssessmentType.LEARNING}>{getMessage('label_assessment_type_learning')}</MenuItem>
                    <MenuItem value={AssessmentType.ORGANIZATIONAL_CONDITIONS}>{getMessage('label_assessment_type_organizational_conditions')}</MenuItem>
                    <MenuItem value={AssessmentType.BEHAVIORAL_CHANGES}>{getMessage('label_assessment_type_behavioral_changes')}</MenuItem>
                    <MenuItem value={AssessmentType.SUSTAINABILITY_CONDITIONS}>{getMessage('label_assessment_type_sustainability_conditions')}</MenuItem>
                    <MenuItem value={AssessmentType.STUDENT_CHARACTERISTICS}>{getMessage('label_assessment_type_student_characteristics')}</MenuItem>
                    <MenuItem value={AssessmentType.STUDENT_LEARNING_OUTCOMES}>{getMessage('label_assessment_type_student_learning_outcomes')}</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="logType">{getMessage("label_choose_log_type")}</InputLabel>
                  <Select
                    labelId="logType"
                    id="logType"
                    name="logType"
                    value={values.logType}
                    label={getMessage("label_choose_log_type")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.logType && errors.logType ? true : false}
                    sx={{mb:"5%"}}
                  >
                    <MenuItem value={LogType.OBSERVATION}>{getMessage("label_log_type_observation")}</MenuItem>
                    <MenuItem value={LogType.CHANGE}>{getMessage("label_log_type_change")}</MenuItem>
                  </Select>
                </FormControl>
              

                <Box sx={{mt:"5%"}}>
                  <Button type="submit" variant="contained" sx={buttonStyle}>
                      <Typography variant="h4">{getMessage("label_submit")}</Typography>
                  </Button>
                </Box>
              </Form>
            )}
        </Formik>
      </Box>
    </Box>
  )
};

export default AddLog;
