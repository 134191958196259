import React from 'react';
import { useField } from 'formik';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

const FrameworkSelector = ({ label, getMessage, competenceAreas, sx = {}, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <FormControl fullWidth error={meta.touched && Boolean(meta.error)} sx={sx}>
      <InputLabel id={`${props.name}-label`}>{getMessage(label)}</InputLabel>
      <Tooltip
        placement="top"
        title={getMessage("tooltip_framework_selector")}
      >
        {/* Tooltip should wrap only the Select component */}
        <Select
          labelId={`${props.name}-label`}
          id={props.name}
          {...field}
          label={getMessage(label)}
        >
          {Object.keys(competenceAreas).map((framework, index) => (
            <MenuItem key={index} value={framework}>
              {framework}
            </MenuItem>
          ))}
        </Select>
      </Tooltip>

      {meta.touched && meta.error ? (
        <div style={{ color: 'red', fontSize: '12px' }}>{meta.error}</div>
      ) : null}
    </FormControl>
  );
};

export default FrameworkSelector;