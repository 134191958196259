import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { Box, Typography, IconButton } from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import RepeatIcon from '@mui/icons-material/Repeat';
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from 'axios';
import { BACKEND_URL } from "../config";
import { useAuthUser } from '../contexts/AuthUserContext';
import { LogType } from '../utils/enums';
import { useMessageService } from '../services/MessageService';
import { localizeAssessmentType } from '../utils/ObjectsUtils';

const CustomTimeline = ({ logs, setLogs, currentMonitoringId }) => {

  const reversedLogs = [...logs].reverse();  // Create a new reversed array
  const { currentUser } = useAuthUser();
  const { getMessage } = useMessageService();

  const handleDelete = async (index) => {
    const originalIndex = logs.length - 1 - index; // Correct index for the original array
    const newLogs = [...logs];
    newLogs.splice(originalIndex, 1); // Delete using the original index
    setLogs(newLogs);

    const serverLogs = {
      userId: currentUser._id, 
      monitoringId: currentMonitoringId,
      logs: newLogs,
    };

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${BACKEND_URL}/logs/${currentMonitoringId}`,
        serverLogs,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if(response.status === 200) {
        console.log('Log deleted successfully:');
      } else {
        console.log('An error occurred while deleting the log:', response);
      }
    } catch (error) {
      console.log('An error occurred while deleting the log:', error);
    }
  };


  if (logs.length === 0) {
    return <Typography mt="50px" variant="h6">{getMessage("label_no_entries")}</Typography>;
  }

  return (

    <Box p={2} m={2}>

      <Typography mb="5vh" variant="h3" fontWeight="bold">
        {getMessage("label_my_training_activity")}
      </Typography>

      <Box sx={{overflowY: 'auto', mt: '15px', height: "70vh", minWidth: "40vw"}}>

        <Timeline>
          {reversedLogs.map((log, index) => {
            return (
              <TimelineItem key={index}>
                
                <TimelineOppositeContent sx={{ display: 'flex', alignItems: 'center', px: '5%' }} variant="body2" color="text.secondary">
                  <Box display="flex" flexDirection="row" alignItems="center" flexGrow={1} sx={{ bgcolor: 'lightgray', borderRadius: '16px', p: 2 }}>
                    <IconButton onClick={() => handleDelete(index)}>
                      <Delete sx={{ color: 'red' }} />
                    </IconButton>

                    <Typography variant="h4" fontWeight="bold" sx={{ ml: 2, whiteSpace: 'nowrap' }}>
                      Session {log.day}
                    </Typography>
                  </Box>
                </TimelineOppositeContent>

                <TimelineSeparator>
                  <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
                    <TimelineDot style={{ backgroundColor: 'rgb(236, 141, 53)' }}>
                      {log.logType === LogType.OBSERVATION ? <VisibilityIcon /> : <RepeatIcon />}
                    </TimelineDot>
                  <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
                </TimelineSeparator>

                <TimelineContent sx={{py: '2%', px: '7%', bgcolor: 'lightgray', borderRadius: '16px', margin: '10px'}}>

                  <Typography variant="h4" component="span" fontWeight="bold">
                    {localizeAssessmentType(log.assessment, getMessage)}
                  </Typography>

                  <Typography variant="h5">
                    {log.description}
                  </Typography>

                </TimelineContent>
              </TimelineItem>
            )
          })}
        </Timeline>
      </Box>
    </Box>
  );
};

export default CustomTimeline;