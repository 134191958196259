import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Box, TextField, Button, Typography } from "@mui/material";
import React, {useState} from "react";
import { useMessageService } from '../services/MessageService';


const AddWorkshop = ({ setQuestions, initialQuestions, workshops, setWorkshops}) => {

    console.log("workshops?", workshops)

    const initialValues = { workshop: "" };
    const [isFirstTime, setIsFirstTime] = useState(!workshops.length);
    const { getMessage } = useMessageService();

    console.log("firstTime?", !workshops.length)

    // Define the Yup validation schema inside the component
    const AddWorkshopSchema = Yup.object().shape({
        workshop: Yup.string()
            .min(2, "The short name must be at least 2 characters long")
            .max(40, "The short name must be at most 40 characters long")
            .required("The category title is required")
            .notOneOf(workshops, "This category title already exists"), // Ensure unique titles
    });

    const handleSubmit = (values, { resetForm }) => {
  const newWorkshopTitle = values.workshop;

  // Update the set of workshops
  setWorkshops(prevWorkshops => {
      // If this workshop does not already exist in the set, add it
      if (!prevWorkshops.includes(newWorkshopTitle)) {
          return [...prevWorkshops, newWorkshopTitle];
      }
      return prevWorkshops;
  });

  // Determine the next questionId by finding the maximum questionId in the current list and adding one
  const nextQuestionId = initialQuestions.reduce((maxId, question) => Math.max(maxId, parseInt(question.questionId)), -1) + 1;

  if (isFirstTime) {
    // If it's the first time, just update the first set of questions.
    setQuestions(prevQuestions => 
      prevQuestions.map((question, index) => 
        (index < initialQuestions.length || initialQuestions.length === 0) 
        ? { ...question, workshop: newWorkshopTitle } 
        : question
      )
    );
    setIsFirstTime(false);
  } else {
    // If it's not the first time, append a new set of initialQuestions with updated questionIds.
    const newQuestions = initialQuestions.map((question, index) => ({
      ...question,
      questionId: String(nextQuestionId + index), // Update questionId
      workshop: newWorkshopTitle
    }));

    setQuestions(prevQuestions => [...prevQuestions, ...newQuestions]);
  }

  resetForm();
};



  const styles = {
    formGroup: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "1rem",
    },
    invalidFeedback: {
      color: "red",
      marginTop: "0.25rem",
    },
  };

  return (
    <Box ml="20px" mr="20px">
      <Box
        sx={{
          borderRadius:"4px",
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={AddWorkshopSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur }) => (
            <Form>
              <div style={styles.formGroup}>
                <TextField
                  id="workshop"
                  label={getMessage("label_add_section_title")}
                  value={values.workshop}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.workshop && errors.workshop ? true : false}
                  sx={{mb:"15px"}}
                  helperText={touched.workshop && errors.workshop ? errors.workshop : ""}
                />
              </div>

              <Box sx={{mt:"15px"}}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "#F7941E",
                    borderRadius: "50px",
                    color: "black",
                    "&:hover": {
                        backgroundColor: "#D17A1D",
                    },
                  }}
                >
                  <Typography variant="h5">{getMessage("label_add")}</Typography>
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  )
};

export default AddWorkshop;
