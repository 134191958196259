import React from 'react';
import { useField } from 'formik';
import { TextField } from '@mui/material';

const TextFieldWrapper = ({ label, getMessage, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <TextField
      {...field}
      {...props}
      label={getMessage(label)}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error ? meta.error : ''}
    />
  );
};

export default TextFieldWrapper;