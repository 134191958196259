import React from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import BarChartReports from './BarChartReports';
import DisplayTextQuestion from './DisplayTextQuestions';
import PropTypes from 'prop-types';
import { localizeAssessmentType } from '../utils/ObjectsUtils';
import { useMessageService } from '../services/MessageService';

export const AssessmentTableResultTabChoice = ({ categories, onChange, data }) => {

    const { getMessage } = useMessageService();

    return (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={data} onChange={onChange} aria-label="">
                {categories.flatMap((category, index) => {
                    const localizedLabel = localizeAssessmentType(category, getMessage);
                    return [
                        <Tab label={localizedLabel} key={`tab-${index * 2}`} />,
                        <Tab icon={<CommentIcon />} key={`comment-${index * 2 + 1}`} />
                    ];
                })}
            </Tabs>
        </Box>
    );
};

export const AssessmentTableResultGraph = ({ categories, data, groupChartData, groupCommentData, fullScreen }) => {

    return (
        categories.map((category, index) => (
            <React.Fragment key={category}>
                <CustomTabPanel value={data} index={index * 2}>
                    <Box 
                        sx={{ 
                            height: fullScreen ? '95vh' : '31vh', 
                            width: '95%', 
                            overflowY: 'auto', // Enable vertical scroll
                            overflowX: 'hidden', 
                            maxHeight: fullScreen ? '95vh' : '31vh' // Set maximum height for scrolling
                        }}
                    >
                        {Object.entries(groupChartData(category)).map(([assessmentName, workshops], index) => (
                            <React.Fragment key={index}>
                                <Typography align="center" color="rgb(102,102,102)" variant="h5" fontWeight="bold" mt='10px'>
                                    {assessmentName}
                                </Typography>
                                {Object.entries(workshops).map(([workshopName, items], subIndex) => (
                                    <BarChartReports data={items} key={subIndex} />
                                ))}
                            </React.Fragment>
                        ))}
                    </Box>
                </CustomTabPanel>

                <CustomTabPanel value={data} index={index * 2 + 1}>
                    <Box style={{ height: `${fullScreen ? '55vh' : '31vh'}`, width: '95%', overflowY: 'auto', overflowX: 'auto', maxHeight: fullScreen ? '95vh' : '31vh' }}>
                        {Object.entries(groupCommentData(category)).map(([assessmentName, workshops], index) => (
                            <React.Fragment key={index}>
                                <Typography align="center" color="rgb(102,102,102)" variant="h5" fontWeight="bold" mt='10px'>
                                    {assessmentName}
                                </Typography>
                                {Object.entries(workshops).map(([workshopName, items], subIndex) => (
                                    <React.Fragment key={subIndex}>
                                        {workshopName !== "default" && workshopName !== assessmentName && (
                                            <Typography align="center" color="rgb(102,102,102)" variant="h6" mt='5px'>
                                                {workshopName}
                                            </Typography>
                                        )}
                                        {items.map((item) => {
                                            return (
                                                <DisplayTextQuestion
                                                    key={item.uniqueQuestionKey}
                                                    title={`${item.question}`}
                                                    content={item.responses}
                                                    displayName={item.displayName}
                                                />
                                            );
                                        })}
                                </React.Fragment>
                                ))}
                            </React.Fragment>
                        ))}
                    </Box>
                </CustomTabPanel>
            </React.Fragment>
        ))
    );
};

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
            }}
            {...other}
        >
            {value === index && (
                <>{children}</>
            )}
        </Box>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};