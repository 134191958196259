import React from 'react';
import { Box, FormControl, InputLabel, MenuItem } from '@mui/material';
import Select from '@mui/material/Select';
import { useMessageService } from '../services/MessageService';
import { AssessmentTableResultTabChoice, AssessmentTableResultGraph } from './AssessmentTabResultsComponents';


const AssessmentTabResultWithFilter = ({ categories, gridRow, data, onChange, groupChartData, groupCommentData, fullScreen=false, allEmails, selectedUser, handleChangeUser }) => {

    const { getMessage } = useMessageService();

    return (
        
        <Box gridColumn={`span ${fullScreen ? 12 : 6}`} gridRow={gridRow} sx={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', borderRadius: '15px', backgroundColor: '#fff' }}>
            <AssessmentTableResultTabChoice
                categories={categories}
                onChange={onChange}
                data={data}
            />

            <Box paddingTop={"10px"} paddingLeft={"5px"}>
                <FormControl variant="outlined" size="small" sx={{ minWidth: 220, marginRight: '20px' }}>
                    <InputLabel id="monitoring">{getMessage("label_choose_teacher")}</InputLabel>

                    <Select
                        labelId="day"
                        id="day"
                        value={selectedUser}
                        onChange={handleChangeUser}
                        autoWidth
                        label={"label_set_selected_user"}
                    >
                        {/* Add an "All" option at the beginning */}
                        <MenuItem value="">
                            {getMessage("label_clear_filter")}
                        </MenuItem>

                        {allEmails && allEmails.map((email) => (
                            <MenuItem key={email} value={email}>
                                {email} 
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
                
            <AssessmentTableResultGraph
                categories={categories}
                data={data}
                groupChartData={groupChartData}
                groupCommentData={groupCommentData}
            />
                        
        </Box> 
    );
};

export default AssessmentTabResultWithFilter;