import React from 'react';
import { useField } from 'formik';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { LearningType } from '../utils/enums';

const LearningTypeSelector = ({ label, getMessage, learningTypes = [LearningType.KNOWLEDGE, LearningType.SKILL], sx = {}, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <FormControl fullWidth error={meta.touched && Boolean(meta.error)} sx={sx}>
      <InputLabel id={`${props.name}-label`}>{getMessage(label)}</InputLabel>
      <Select
        labelId={`${props.name}-label`}
        id={props.name}
        {...field}
        label={getMessage(label)}
      >
        {learningTypes.map((type, index) => (
          <MenuItem key={index} value={type}>
            {getMessage(`label_${type.toLowerCase()}`)}
          </MenuItem>
        ))}
      </Select>
      {meta.touched && meta.error ? (
        <div style={{ color: 'red', fontSize: '12px' }}>{meta.error}</div>
      ) : null}
    </FormControl>
  );
};

export default LearningTypeSelector;